import useTranslation from '@hooks/useTranslation';

import styles from './Industry.module.css';

interface IndustryProps {
    industry?: string;
}
const ns = 'pages/max/design2024/components/SectionIncreasedSales/index';
export default function Industry({ industry }: IndustryProps) {
    const { t } = useTranslation(ns);
    return (
        <div className={styles.industry}>
            <h4>{t('increasedSalesItemsBusinessIndustryLabel')}:</h4>
            <p>{industry}</p>
        </div>
    );
}
