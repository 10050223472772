import styles from './Sales.module.css';

interface SalesProps {
    number: JSX.Element;
    desc: JSX.Element;
}

export default function Sales({ number, desc }: SalesProps) {
    return (
        <div className={styles.sales}>
            <p className={styles.number}>{number}</p>
            <p className={styles.desc}>{desc}</p>
        </div>
    );
}
