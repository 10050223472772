import React, { ReactNode } from 'react';

export interface TabProps {
    title?: ReactNode;
    icon?: ReactNode;
    customClassName?: string;
    active?: boolean;
    children: React.ReactNode;
}

export function Tab(props: TabProps): React.ReactElement {
    return <div />;
}
