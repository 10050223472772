import { useCallback, useRef } from 'react';

import clsx from 'clsx';

import { useElementFocus } from '../hooks/useElementFocus.hook';
import styles from './DotActivator.module.css';

export interface DotsActivatorProps {
    isSelected: boolean;
    index: number;
    focusIfSelected: boolean;
    className?: string;
    onClick(index: number): void;
}

export function DotActivator({ index, isSelected, focusIfSelected, className, onClick }: DotsActivatorProps): React.ReactElement {
    const handleClick = useCallback(() => onClick(index), [onClick, index]);
    const customRef = useElementFocus(focusIfSelected && isSelected);
    //const customRef = useRef<HTMLDivElement>(null);

    return (
        <div
            role="dot"
            //tabIndex={0}
            tabIndex={focusIfSelected && isSelected ? -1 : 0}
            className={clsx(styles.dot, isSelected && styles.active, className)}
            onClick={handleClick}
            ref={customRef}
        />
    );
}
