import { useEffect, useRef } from 'react';

export function useElementFocus(isSelected: boolean): React.RefObject<HTMLDivElement> {
    const customRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (isSelected) {
            customRef.current?.focus();
        }
    }, [isSelected]);

    return customRef;
}
