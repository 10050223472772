import React from 'react';

import type { TabsProps } from '@components/pages/max/TabsAccordion/Tabs';
import { Tabs as DefaultTabs } from '@components/pages/max/TabsAccordion/Tabs';

import styles from './Tabs.module.css';

export function Tabs(props: TabsProps) {
    return (
        <DefaultTabs
            {...props}
            themeStyles={styles}
        />
    );
}
