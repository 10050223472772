import React from 'react';

import CustomLink from '@components/global/CustomLink';

import styles from './Link.module.css';
import { ReactComponent as ArrowBlue } from './assets/arrow-blue.svg';

interface LinkProps {
    color?: 'blue';
    label: string | JSX.Element;
    href?: string;
    customLink?: JSX.Element | undefined;
    className?: string;
    arrow?: boolean;
    onClick?: (e: React.MouseEvent) => void;
}

const Link = ({ href, customLink, color = 'blue', label, className, arrow = true, ...otherProps }: LinkProps) => {
    if (href) {
        return (
            <a
                {...otherProps}
                href={href}
                className={[styles.btn, className, styles[color], arrow ? styles.arrow : null].filter(Boolean).join(' ')}
            >
                <span>{label}</span>
                {arrow && (
                    <ArrowBlue
                        width={16}
                        height={16}
                    />
                )}
            </a>
        );
    } else if (customLink) {
        return (
            <CustomLink
                {...customLink.props}
                {...otherProps}
                className={[styles.link, className, styles[color], arrow ? styles.arrow : null].filter(Boolean).join(' ')}
            >
                <span>{label} </span>
                {arrow && (
                    <ArrowBlue
                        width={16}
                        height={16}
                    />
                )}
            </CustomLink>
        );
    } else {
        return null;
    }
};

export default Link;
