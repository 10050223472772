import React from 'react';

import styles from './CustomTab.module.css';

interface CustomTabProps {
    picture: JSX.Element;
    caseStudies?: {
        logo?: JSX.Element;
        desc?: JSX.Element;
        link?: JSX.Element;
    };
    industry?: JSX.Element;
    sales?: JSX.Element;
}

export default function CustomTab({ caseStudies, picture, industry, sales }: CustomTabProps): JSX.Element {
    return (
        <div className={styles.split}>
            <div className={styles.caseStudies}>
                <div className={styles.logo}>{caseStudies?.logo}</div>
                <div className={styles.desc}>{caseStudies?.desc}</div>
                <div className={styles.action}>{caseStudies?.link}</div>
            </div>
            <div className={styles.picture}>{picture}</div>
            <div className={styles.details}>
                <div className={styles.industry}>{industry}</div>
                <div className={styles.sales}>{sales}</div>
            </div>
        </div>
    );
}
